<template>
  <div class="events-page">
    <HierarchySidebar />
    <div
      :class="[
        'events-page__main',
        { 'events-page__main--is-sidebar-expanded': isSidebarExpanded },
      ]"
    >
      <Header />
      <EventTable v-if="bookEventsRoute" />
      <CompetitionsSubscriptionTable v-if="!bookEventsRoute" />
      <EventsSidebar />
      <Footer />
    </div>
    <EventDetails
      v-if="bookEventsRoute"
    />
    <EventsSelectionPrompt />
    <SuggestedMappingPopup
      v-if="eventForMapping"
      @closePopup="closePopup"
      :mapping-type="EVENTS_MAPPING"
      :edit-data="eventForMapping"
      :is-event-list="true"
    />
    <SubscriptionModal
      v-if="isSubscriptionModalOpen"
    />
  </div>
</template>

<script>
import {
  debounce,
  find,
  assign,
  isEqual,
} from 'lodash';
import {
  onMounted,
  onUnmounted,
  computed,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { mappingTypes } from '@/services/helpers/mappings-mapper';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import SuggestedMappingPopup from '@/components/mapping/common/SuggestedMappingPopup';
import HierarchySidebar from '@/components/events/HierarchySidebar';
import EventTable from './EventTable';
import eventsHelper from './events-helper';
import EventDetails from './EventDetails';
import EventsSelectionPrompt from './EventsSelectionPrompt';
import SubscriptionModal from './SubscriptionModal';
import EventsSidebar from './EventsSidebar';
import CompetitionsSubscriptionTable from './competitions-subscription/CompetitionsSubscriptionTable';

const { EVENTS_MAPPING } = mappingTypes;

export default {
  components: {
    Header,
    Footer,
    HierarchySidebar,
    EventTable,
    EventDetails,
    EventsSelectionPrompt,
    SuggestedMappingPopup,
    SubscriptionModal,
    EventsSidebar,
    CompetitionsSubscriptionTable,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const eventForMapping = computed(() => store.getters.eventListMappingEvent);
    const isSidebarExpanded = computed(() => store.getters.sidebarExpanded);
    const isSubscriptionModalOpen = computed(() => store.getters.subscriptionModalOpen);

    const bookEventsRoute = computed(() => route.name === 'events_book_events');

    const loadEvents = () => {
      store.dispatch('loadEvents');
    };
    const closePopup = (message) => {
      const shouldReload = message === 'reload';
      if (shouldReload) {
        const { eventList } = store.getters;
        const mappedEvent = find(eventList, { eventId: eventForMapping.value.row.id });
        assign(mappedEvent, { isMapped: true });
        store.dispatch('updateEventList', eventList);
      }
      store.dispatch('setEventListMappingEvent', null);
    };

    watch(
      () => [
        store.getters.sidebarSportId,
        store.getters.sidebarRegionId,
        store.getters.sidebarCompetitionId,
        route.name,
        store.getters.eventListAppliedSort,
        store.getters.eventListAppliedFilters,
        store.getters.search,
        store.getters.rowsPerPage,
        store.getters.currentPage,
      ],
      debounce((newValues, oldValues) => {
        if (isEqual(newValues, oldValues)) return;

        const [oldSportId, oldRegionId, oldCompetitionId, oldDisplayOption, oldFilters, oldSort] = oldValues ?? [];
        const [newSportId, newRegionId, newCompetitionId, newDisplayOption, newFilters, newSort] = newValues;
        const nothingWasSelected = !oldSportId && !oldRegionId && !oldCompetitionId;
        const somethingIsSelected = newSportId || newRegionId || !newCompetitionId;
        const displayOptionChanged = oldDisplayOption !== newDisplayOption;
        const filtersOrSortChanged = !isEqual(oldFilters, newFilters) || !isEqual(oldSort, newSort);

        if (displayOptionChanged || filtersOrSortChanged || (nothingWasSelected && somethingIsSelected)) {
          store.dispatch('loadSidebar');
        }

        loadEvents();
      }, 1),
    );

    watch(
      () => route.name,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        store.dispatch('updateSelectedEvents', []);
      },
    );

    watch(
      () => store.getters.operator,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        store.dispatch('updateColumns', eventsHelper.createColumns());
      },
    );

    onMounted(() => {
      loadEvents();
      store.dispatch('loadSidebar');
      store.dispatch('updateSearch', '');
      store.dispatch('updateColumns', eventsHelper.createColumns({
        isHuddleOperator: store.getters.operator === 'Huddle',
      }));
    });
    onUnmounted(() => {
      store.dispatch('updateSearch', '');
      store.dispatch('updateColumns', {});
      store.dispatch('updateSelectedEvents', []);
      store.dispatch('unsubscribeEventList');
    });

    return {
      route,
      loadEvents,
      eventForMapping,
      EVENTS_MAPPING,
      isSubscriptionModalOpen,
      isSidebarExpanded,
      closePopup,
      bookEventsRoute,
    };
  },
};
</script>

<style lang="scss">
.events-page {
  .events-page__main {
    width: calc(100% - $sidebarWidthCollapsed);

    &--is-sidebar-expanded {
      width: calc(100% - $sidebarWidth);
    }
  }
}
@media(max-width: 1140px) {
  .events-page {
    .header-bottom {
      .filters-dropdown-filter {
        .filters-dropdown-filter-value {
          min-width: 80px;
          max-width: 120px;
        }
      }
    }
  }
}
@media(max-width: 900px) {
  .events-page {
    .events-page__main { width: 100%; }
    .header-bottom {
      .filters-dropdown-filter {
        .filters-dropdown-filter-value {
          min-width: 60px;
          max-width: 100px;
        }
      }
    }
  }
}
@media(max-width: 768px) {
  .events-page {
    .hierarchy-sidebar { display: none; }
    .header-bottom {
      .filters-dropdown-filter {
        .filters-dropdown-filter-value {
          max-width: 80px;
        }
      }
    }
  }
}
</style>
